import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "fileInput",
    "fileName",
    "icon",
    "fileValidation",
    "resumeLoader",
  ];
  originalIconUrl = null;

  connect() {
    this.originalIconUrl = this.iconTarget.src;
  }

  handleFileUpload(event) {
    const file = this.fileInputTarget.files[0];
    const allowedExtensions = /(\.pdf|\.docx|\.doc)$/i;

    if (file) {
      if (!allowedExtensions.exec(file.name)) {
        this.fileValidationTarget.textContent =
          "Invalid file type. Please upload a PDF, DOCX, or DOC file.";
        this.clearFileDetails();
      } else {
        this.fileValidationTarget.textContent = "";
        this.fileNameTarget.textContent = file.name;
        this.showFileDetails();
      }
    } else {
      this.clearFileDetails();
    }
  }

  handleResumeFileUpload(event) {
    const file = this.fileInputTarget.files[0];
    const allowedExtensions = /(\.pdf|\.docx|\.doc)$/i;
    const submitButton = document.getElementById("resume-continue-btn");
    if (file) {
      if (!allowedExtensions.exec(file.name)) {
        this.fileValidationTarget.textContent =
          "Invalid file type. Please upload a PDF, DOCX, or DOC file.";
        this.clearFileDetails();
        submitButton.disabled = true;
        return;
      } else {
        this.fileValidationTarget.textContent = "";
        this.fileNameTarget.textContent = file.name;
        this.showFileDetails();
      }
    } else {
      this.clearFileDetails();
    }
    submitButton.disabled = false;
  }

  showFileDetails() {
    const newIconUrl = "/images/document.png";
    this.iconTarget.src = newIconUrl;
  }

  clearFileDetails() {
    this.fileNameTarget.textContent = "";
    this.iconTarget.src = this.originalIconUrl;
  }

  uploadResume(event) {
    event.preventDefault();
    this.resumeLoaderTarget.classList.remove("hidden");
    event.currentTarget.form.submit();
  }
}
