import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["goldFeatureModal", "switch"];

  closeFeatureModal() {
    document.body.style.overflow = "";
    if (this.hasGoldFeatureModalTarget && this.goldFeatureModalTarget) {
      this.goldFeatureModalTarget.remove();
    } else {
      document.getElementById("gold_subscription").remove();
    }
  }
  toggle() {
    const checkbox = this.switchTarget;
    checkbox.checked = !checkbox.checked;
  }
  openFeatureModal() {
    document.body.style.overflow = "hidden";
  }
}
