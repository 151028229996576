import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

let location_radius = null;
export default class extends Controller {
  static values = {
    locationRadius: Object,
  };

  static targets = [
    "tab",
    "tabs",
    "searchInput",
    "searchCategoryForm",
    "filtersForm",
    "applyFiltersButton",
    "toggleDiv",
    "closeChat",
    "chatDrawer",
    "chatSideDrawer",
  ];

  connect() {
    console.log("search connect");
    this.timeout = null;
    jQuery("#ai_search_dropdown_filter").off("change");
    jQuery("#ai_search_dropdown_filter")
      .select2()
      .on("change", (e) => {
        e.currentTarget.form.requestSubmit();
      });

    document.addEventListener("click", (event) => {
      const asideDrawer = document.querySelector(".aside-drawer");
      const drawerContent = document.querySelector(".aside-drawer > div");

      if (
        asideDrawer &&
        !asideDrawer.classList.contains("hidden") &&
        !drawerContent.contains(event.target) &&
        !event.target.matches(".opendrawer, .opendrawer *")
      ) {
        this.closeFilter();
      }
    });

    const search_location_input = document.getElementById(
      "search-location-input"
    );
    if (search_location_input) {
      console.log("search_location_input");
      if (typeof google !== "undefined" && typeof google.maps !== "undefined") {
        this.autocomplete = new google.maps.places.Autocomplete(
          search_location_input,
          {
            types: ["(cities)"],
          }
        );
        this.autocomplete.addListener(
          "place_changed",
          this.populateLocation.bind(this)
        );
      }
    }
  }

  populateLocation() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error("No location data for this place");
      return;
    }

    let country = "";
    let state = "";
    let city = "";

    this.autocomplete.setTypes(["(cities)"]);

    place.address_components.forEach((component) => {
      if (component.types.includes("country")) {
        country = component.short_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zipcode = component.long_name;
      } else if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
    });

    location_radius = {
      search: document.getElementById("search-location-input").value,
      country: country,
      state: state,
      city: city,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };

    document.getElementById("location_radius_data").value =
      JSON.stringify(location_radius);

    const form = document.getElementById("search-filter-form");
    if (form) {
      form.requestSubmit();
    }
  }

  initRadiusSlider() {
    const radius_slider = document.getElementById("db-rangeslider");
    if (radius_slider && !radius_slider.noUiSlider) {
      const labels = {
        1: "1",
        10: "•",
        20: "•",
        30: "•",
        50: "•",
        70: "•",
        100: "•",
        130: "•",
        160: "•",
        190: "•",
        200: "200",
      };

      noUiSlider.create(radius_slider, {
        start: 0,
        connect: [true, false],
        tooltips: {
          to: function (value) {
            return value > 200 ? "200+" : parseInt(value);
          },
        },
        range: {
          min: 1,
          "10%": 10,
          "20%": 20,
          "30%": 30,
          "40%": 50,
          "50%": 70,
          "60%": 100,
          "70%": 130,
          "80%": 160,
          "90%": 190,
          max: 200,
        },
        pips: {
          mode: "steps",
          filter: function (value, type) {
            return type === 0 ? -1 : 1;
          },
          format: {
            to: function (value) {
              return labels[value] !== undefined ? labels[value] : "";
            },
          },
        },
      });

      radius_slider.noUiSlider.on("change", function (values, handle) {
        if (location_radius != null) {
          location_radius.radius = parseInt(values[handle]);
          document.getElementById("location_radius_data").value =
            JSON.stringify(location_radius);
          const form = document.getElementById("search-filter-form");
          if (form) {
            form.requestSubmit();
          }
        }
      });
    }
  }

  activateTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
  }

  activateTabs(event) {
    const clickedTab = event.currentTarget;
    this.tabsTargets.forEach((tab) => {
      tab.classList.remove("border-b-2");
      tab.classList.remove("border-[#3076FF]");
    });
    clickedTab.classList.add("border-b-2");
    clickedTab.classList.add("border-[#3076FF]");
  }

  debounceSearch(el) {
    if (!this.hasSearchInputTarget) return;
    clearTimeout(this.timeout);
    const value = this.searchInputTarget.value;
    document.getElementById("search-button").disabled = true;
    if (value.trim() == "") {
      document.getElementById("search-button").disabled = false;
      return false;
    }
    this.timeout = setTimeout(() => {
      this.searchCategories(value);
    }, 800);
  }

  searchCategories(query) {
    if (!this.hasSearchCategoryFormTarget) return;
    document.getElementById("search_categories_query").value = query;
    const form = this.searchCategoryFormTarget;
    form.addEventListener(
      "turbo:submit-end",
      () => {
        document.getElementById("search-button").disabled = false;
      },
      { once: true }
    );
    form.requestSubmit();
  }

  search(event) {
    event.currentTarget.form.requestSubmit();
  }

  openFilter() {
    const asideDrawer = document.querySelector(".aside-drawer");
    var body = document.body;
    body.classList.add("overflow-hidden");
    if (asideDrawer.classList.contains("hidden")) {
      asideDrawer.classList.remove("hidden");
      asideDrawer.classList.add("flex");

      void asideDrawer.offsetWidth;

      asideDrawer.classList.remove("opacity-0");
      if (asideDrawer.children.length === 1) {
        asideDrawer.children[0].classList.add("!translate-x-0");
      }
    }

    if (asideDrawer) {
      if (!asideDrawer.firstElementChild.classList.contains("max-w-[500px]")) {
        const button_wrap = document.getElementsByClassName("button-wrap");
        for (let wrap of button_wrap) {
          wrap.removeAttribute("style");
        }

        const filterSort = document.getElementsByClassName("filter-sort");
        for (let sort of filterSort) {
          sort.removeAttribute("style");
        }

        const filterItems = document.getElementsByClassName("filter-items");
        for (let item of filterItems) {
          item.removeAttribute("style");
        }

        const openFilters = document.querySelector(".openfilters");
        if (openFilters) {
          openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
        }
      }
    }
  }

  showVideoask(event) {
    event.preventDefault();
  }

  closeFilter() {
    const asideDrawer = document.querySelector(".aside-drawer");
    var body = document.body;
    body.classList.remove("overflow-hidden");
    asideDrawer.classList.add("opacity-0");
    if (asideDrawer.children.length === 1) {
      asideDrawer.children[0].classList.remove("!translate-x-0");
      asideDrawer.children[0].classList.remove("max-w-[500px]");
    }

    setTimeout(() => {
      asideDrawer.classList.remove("flex");
      asideDrawer.classList.add("hidden");
    }, 300);
  }

  openAllFilters() {
    const asideDrawer = document.querySelector(".aside-drawer");
    if (asideDrawer) {
      [...asideDrawer.children].forEach((child) =>
        child.classList.toggle("max-w-[500px]")
      );
    }

    const filterItems = document.getElementsByClassName("filter-items");
    for (let item of filterItems) {
      item.style.display = item.style.display === "none" ? "flex" : "none";
    }

    const filterSort = document.getElementsByClassName("filter-sort");
    for (let sort of filterSort) {
      sort.style.display = sort.style.display === "flex" ? "none" : "flex";
    }

    const buttonWrap = document.getElementsByClassName("button-wrap");
    for (let wrap of buttonWrap) {
      wrap.style.display = wrap.style.display === "none" ? "flex" : "none";
    }

    const openFilters = document.querySelector(".openfilters");
    if (openFilters.innerHTML.includes("Edit Filters")) {
      openFilters.innerHTML = `Save Filters <i class="anron-check text-[14px]"></i>`;
    } else {
      openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
      document.getElementById("filters-form").requestSubmit();
    }

    const sortable_left = document.getElementById("example2-left");
    const sortable_right = document.getElementById("example2-right");
    this.initSortable(sortable_left);
    this.initSortable(sortable_right);
  }

  initSortable(element) {
    Sortable.create(element, {
      group: "shared",
      animation: 30,
      onStart: function (evt) {
        evt.item.classList.add("bg-white", "shadow-sm");
      },
      onEnd: function (evt) {
        evt.item.classList.remove("bg-white", "shadow-sm");

        const targetElement =
          evt?.item?.lastElementChild?.previousElementSibling;

        if (evt.to.id === "example2-left") {
          if (targetElement) {
            targetElement.value = true;
          }
        }

        if (evt.to.id === "example2-right") {
          if (targetElement) {
            targetElement.value = false;
          }
        }
      },
    });
  }

  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#ai_search_dropdown_filter");
  }

  applyFilters(event) {
    document.getElementById("show_results_count").value = false;
    this.applyFiltersButtonTarget.disabled = true;
    this.applyFiltersButtonTarget.lastElementChild.classList.remove("hidden");
    const form = this.filtersFormTarget;
    form.addEventListener(
      "turbo:submit-end",
      () => {
        if (form.querySelector("turbo-frame")) {
          this.applyFiltersButtonTarget.disabled = false;
          this.applyFiltersButtonTarget.lastElementChild.classList.add(
            "hidden"
          );
          this.closeFilter();
        }
      },
      { once: true }
    );
    form.requestSubmit();
  }

  show() {
    if (this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.remove("hidden");
    }
  }

  hide() {
    if (!this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.add("hidden");
    }
  }

  toggleHistory(event) {
    event.preventDefault();
    const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;

    if (isSmallScreen) {
      this.chatSideDrawerTarget.classList.toggle("slide-out");
    }
  }

  closeHistory(event) {
    event.preventDefault();
    if (this.chatSideDrawerTarget.classList.contains("slide-out")) {
      this.chatSideDrawerTarget.classList.remove("slide-out");
    }
  }
}
