import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    jobTitle: Array,
    skills: Array,
    companyTitle: Array,
    universityFilter: Array,
    degreeFilter: Array,
    industryFilter: Array,
  };

  connect() {
    this.timeout = null;
    const filters = [
      "country_filter",
      "language_filter",
      "company_filter",
      "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
    ];

    filters.forEach((filter) => $(`#${filter}`).select2());
    this.select2tags();

    const commonSelect2Config = {
      ajax: {
        delay: 250,
        dataType: "json",
        cache: false,
      },
      multiple: true,
      placeholder: "Select an option",
    };

    const createSelect2Config = (url, type) => ({
      ...commonSelect2Config,
      ajax: {
        ...commonSelect2Config.ajax,
        url,
        data: (params) => ({
          q: params.term,
          ...(type && { type }),
        }),
        processResults: (data) => {
          const isSelected = data.includes("Facebook");
          if (isSelected) {
            return {
              results: data.map((item) => {
                return { id: item[0], text: item[0], selected: true };
              }),
            };
          }
          return {
            results: data.map((item) => {
              return { id: item[0], text: item[0] };
            }),
          };
        },
      },
    });

    $("#skill_search").select2(createSelect2Config("/search/skills_search"));

    $("#company_title").select2(
      createSelect2Config("/search/facet_search", "company_title")
    );

    $("#university_filter").select2(
      createSelect2Config("/search/facet_search", "schools")
    );

    $("#job_title_filter").select2(
      createSelect2Config("/search/facet_search", "position_title")
    );

    $("#degree_filter").select2(
      createSelect2Config("/search/facet_search", "degrees")
    );

    $("#industry_filter").select2(
      createSelect2Config("/v2/companies/facet_search", "industry")
    );

    if (this.jobTitleValue.length > 0) {
      const jobTitleFilter = $("#job_title_filter");

      this.jobTitleValue.forEach((jobTitle) => {
        if (
          jobTitleFilter.find("option[value='" + jobTitle + "']").length === 0
        ) {
          const newOption = new Option(jobTitle, jobTitle, true, true);
          jobTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.skillsValue.length > 0) {
      const skillFilter = $("#skill_search");
      this.skillsValue.forEach((skill) => {
        if (skillFilter.find("option[value='" + skill + "']").length === 0) {
          const newOption = new Option(skill, skill, true, true);
          skillFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.companyTitleValue.length > 0) {
      const companyTitleFilter = $("#company_title");
      this.companyTitleValue.forEach((companyTitle) => {
        if (
          companyTitleFilter.find("option[value='" + companyTitle + "']")
            .length === 0
        ) {
          const newOption = new Option(companyTitle, companyTitle, true, true);
          companyTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.universityFilterValue.length > 0) {
      const universityFilter = $("#university_filter");
      this.universityFilterValue.forEach((university) => {
        if (
          universityFilter.find("option[value='" + university + "']").length ===
          0
        ) {
          const newOption = new Option(university, university, true, true);
          universityFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.degreeFilterValue.length > 0) {
      const degreeFilter = $("#degree_filter");
      this.degreeFilterValue.forEach((degree) => {
        if (degreeFilter.find("option[value='" + degree + "']").length === 0) {
          const newOption = new Option(degree, degree, true, true);
          degreeFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.industryFilterValue.length > 0) {
      const industryFilter = $("#industry_filter");
      this.industryFilterValue.forEach((industry) => {
        if (
          industryFilter.find("option[value='" + industry + "']").length === 0
        ) {
          const newOption = new Option(industry, industry, true, true);
          industryFilter.append(newOption).trigger("change");
        }
      });
    }
  }

  searchByName(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    if (value.trim() == "") {
      return false;
    }
    this.timeout = setTimeout(() => {
      const form = document.getElementById("search-filter-form");
      if (form) {
        form.requestSubmit();
      }
    }, 600);
  }

  select2tags() {
    var tags = [];
    var placeholder = "Select an option";

    $(".select2multiple select").each(function (e) {
      const $select = $(this);
      $select
        .select2({
          placeholder: placeholder,
        })
        .on("select2:select", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          if (!tags[selector.id]) {
            tags[selector.id] = [];
          }
          prepare_tags_array(selector.id);
          const exists = tags[selector.id].some(
            (tag) => tag.id === e.params.data.id
          );
          if (!exists) {
            tags[selector.id].push({
              value: e.params.data.text,
              id: e.params.data.id,
              key: selector.id,
            });
          }

          updateCustomPlaceholder($select);
          displayTags(selector);
        })
        .on("select2:unselect", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          const indexToRemove = tags[selector.id].findIndex(
            (tag) => tag.id === e.params.data.id
          );
          if (indexToRemove !== -1) {
            tags[selector.id].splice(indexToRemove, 1);
          }

          updateCustomPlaceholder($select);
          displayTags(selector);
        });
    });

    $(".tags-area").on("click", ".tag", function (e) {
      const selectorId = e.target.parentNode.dataset.element;
      const tagIdToRemove = e.target.parentNode.dataset.select;
      prepare_tags_array(selectorId);

      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }

      const indexToRemove = tags[selectorId].findIndex(
        (tag) => tag.id === tagIdToRemove
      );

      if (indexToRemove !== -1) {
        tags[selectorId].splice(indexToRemove, 1);
      }
      const $select = $(`.select2multiple #${selectorId.replace("_tags", "")}`);
      let selectedValues = $select.val() || [];
      selectedValues = selectedValues.filter((val) => val !== tagIdToRemove);
      $select.val(selectedValues).trigger("change");

      e.target.parentNode.remove();

      return false;
    });

    function displayTags(element) {
      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }
      element.innerHTML = "";
      tags[element.id].forEach((tag) => {
        if (tag.key == element.id) {
          element.innerHTML += `<a href="javascript:void(0)" class="tag" data-select="${tag.id}" data-element="${element.id}">
          <span class="value">${tag.value}</span> <i class='anron-cross text-[15px] text-[#05123752]'></i>
          </a>`;
        }
      });
    }

    function prepare_tags_array(selectorId) {
      if (!tags[selectorId]) {
        tags[selectorId] = [];
      }
      const tag_items = document.getElementsByClassName("tag");
      if (tag_items.length > 0) {
        for (const item of tag_items) {
          const value = item.dataset.select;

          const find_value = tags[item.dataset.element]
            ? tags[item.dataset.element].find((tag) => tag.id === value)
            : undefined;
          if (!find_value && tags[item.dataset.element]) {
            tags[item.dataset.element].push({
              value: value,
              id: value,
              key: item.dataset.element,
            });
          }
        }
      }
    }

    function updateCustomPlaceholder($select) {
      const selectedValues = $select.val();
      const val =
        selectedValues && selectedValues.length > 0
          ? selectedValues[0] + " (" + selectedValues.length + ")"
          : placeholder;

      $select.next().find(".select2-selection__custom").html(val);
    }
  }

  clearFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    const filterKey = event.target.dataset.filterKey;
    if (
      [
        "skills",
        "location",
        "languages",
        "country_codes",
        "industry_filter",
        "country_filter",
        "company_name",
        "field_of_study",
        "university",
        "job_title",
      ].includes(filterKey)
    ) {
      const select =
        event.currentTarget.parentNode.nextElementSibling.querySelector(
          "select"
        );
      $(`#${select.id}`).val([]).trigger("change");
      $(`#${select.id}_tags`).html("");
    }

    if (["company_name_filter"].includes(filterKey)) {
      $(`#${filterKey}`).val("");
    }

    if (
      [
        "profile_features",
        "score",
        "years_of_experience",
        "company_size",
        "no_of_employees",
        "contact_info",
      ].includes(filterKey)
    ) {
      $(`.${filterKey}`).prop("checked", false);
    }

    const span_tag = event.currentTarget.previousElementSibling;
    if (span_tag) {
      span_tag.classList.remove("has-dot");
    }
  }
}
