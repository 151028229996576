import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hybrid-search"
export default class extends Controller {
  connect() {
    const select6 = jQuery("#select6");
    this.submitByFilters(select6);
    const select7 = jQuery("#select7");
    this.submitByFilters(select7);

    jQuery("#select8")
      .select2()
      .on("change", (e) => {
        e.currentTarget.form.requestSubmit();
      });
  }

  submitByFilters(selectElement) {
    selectElement.off("change");
    selectElement.select2().on("change", (e) => {
      e.currentTarget.form.requestSubmit();
    });
  }

  submitByScores(event) {
    event.currentTarget.form.requestSubmit();
  }

  submitByProfileFeatures(event) {
    event.currentTarget.form.requestSubmit();
  }
  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#select6");
    destroySelect2("#select7");
    destroySelect2("#select8");
    destroySelect2("#skill_search");
  }
}
