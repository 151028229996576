import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static values = { data: Array, labels: Array }

  connect() {
    this.renderChart();
  }

  renderChart() {
    const ctx = this.element.getContext('2d');

    var gradient = ctx.createLinearGradient(237, 237, 255, 400);
    gradient.addColorStop(0, 'rgba(102, 78, 255, 0.2)');
    gradient.addColorStop(1, 'rgba(102, 78, 255, 0)');

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labelsValue,
        datasets: [{
          label: "",
          backgroundColor: gradient,
          borderColor: '#6C4CF3',
          borderWidth: 1,
          pointBackgroundColor: 'rgba(72, 118, 255, 1)',
          data: this.dataValue,
          fill: true,
          borderDash: [2, 2],
          pointRadius: 0
        }],
      },
      options: {
        responsive: true,
        layout: {
          padding: 20,
        },
        plugins: {
          legend: false,
        },
        title: {
          display: false,
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              stepSize: 5,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            ticks: {
              beginAtZero: true,
            },
            grid: {
              display: false,
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
          }
        }
      }
    });
  }
}
