import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["workflowModal"];

  openContactModal() {
    document.body.style.overflow = "hidden";
  }

  closeWorkflowModal(){
    document.body.style.overflow = "";
    if (this.hasWorkflowModalTarget) {
      this.workflowModalTarget.remove();
    } 
  }
}
