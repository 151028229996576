import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "viewField", "attributesForm"];

  connect() {
    if (window.location.href.includes("people")) {
      $("#ai_search_dropdown_filter")?.select2();
    }
  }

  disconnect() {
    $(this.element).find("#ai_search_dropdown_filter").select2("destroy");
  }

  selectView(event) {
    event.preventDefault();

    const viewValue = event.currentTarget.dataset.view;
    this.viewFieldTarget.value = viewValue;
    this.formTarget.submit();
  }

  closePhotoModal(){
    if (this.hasAttributesFormTarget) {
      this.attributesFormTarget.remove();
    }
  }

  submitAttributesForm(event){
    event.preventDefault();
    this.formTarget.requestSubmit();
    this.closePhotoModal();
  }
}
