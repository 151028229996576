import { Controller } from "@hotwired/stimulus";

var splide_slider = null;
export default class extends Controller {
  static targets = [
    "imagePreview",
    "addressInput",
    "cityInput",
    "zipcodeInput",
    "existingSkillsInput",
  ];

  connect() {
    AOS.init({
      duration: 1200,
      once: true,
    });

    jQuery("#select6").select2();

    if (this.element.dataset.layout.trim() == "location") {
      if (
        typeof google !== "undefined" &&
        typeof google.maps !== "undefined" &&
        this.hasAddressInputTarget
      ) {
        this.autocomplete = new google.maps.places.Autocomplete(
          this.addressInputTarget
        );
        this.autocomplete.addListener(
          "place_changed",
          this.populateLocation.bind(this)
        );
      }
    }

    if (this.element.dataset.layout.trim() == "skills") {
      this.renderSkillsDropdown();
    }

    if (
      this.element.dataset.layout.trim() == "experience" ||
      this.element.dataset.layout.trim() == "education"
    ) {
      splide_slider = new Splide("#experiencesliders", {
        perPage: 1,
        paginationDirection: "ttb",
        drag: false,
        focus: "center",
        trimSpace: false,
      }).mount();
      $("#add_more_record").click();

      if (this.element.dataset.size > "0") {
        const arrows = document.getElementsByClassName("splide__arrows")[0];
        if (arrows) {
          arrows?.classList.remove("!hidden");
        }
      }
    }
  }

  populateLocation() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error("No location data for this place");
      return;
    }

    let country = "";
    let state = "";
    let zipcode = "";
    let city = "";

    place.address_components.forEach((component) => {
      if (component.types.includes("country")) {
        country = component.short_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("postal_code")) {
        zipcode = component.long_name;
      } else if (component.types.includes("administrative_area_level_2")) {
        city = component.long_name;
      }
    });

    this.zipcodeInputTarget.value = zipcode;
    $("#select6").val(country).trigger("change");
    console.log(city, "city");

    const cityValue = `${
      city == null || city == "" ? "" : `${city} ,`
    } ${state}`;

    this.cityInputTarget.value = cityValue.trim();

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    document.getElementById("latitude").value = latitude;
    document.getElementById("longitude").value = longitude;
  }

  disconnect() {
    $(this.element).find("#select6").select2("destroy");
    splide_slider = null;
  }

  renderSkillsDropdown() {
    $("#skills_multiples").select2({
      placeholder: "Skill (ex: Project Management)",
      tags: true,
    });

    $("#skills_multiples").on("select2:open select2:close", function () {
      let searchField = $(".select2-search__field");
      searchField.attr("placeholder", "Skill (ex: Project Management)");
      searchField.on("input", function () {
        if ($(this).val() === "") {
          $(this).attr("placeholder", "Skill (ex: Project Management)");
        }
      });
    });

    $("#skills_multiples").on("change", function () {
      var selectedOptions = $(this).val();
      var displayDiv = $("#selected-options");

      if (selectedOptions == null || selectedOptions == "") {
        $(".new_skills").remove();
      } else {
        $("#skill_fallback_case").addClass("hidden");
      }

      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach(function (option, index) {
          if (!displayDiv.find("li:contains('" + option + "')").length) {
            displayDiv.append(
              `<li id="__skill_${index}" class="new_skills">
                <span class="bg-[#0512370A] cursor-pointer text-[#051237C2] text-[12px] leading-4 font-medium font-text_font p-[8px_12px] inline-flex gap-[8px] items-center justify-center rounded-[50px] hover:bg-[#FFEDF0] hover:text-[#FD306E] [&_i:hover]:text-[#FD306E]">${option} 
                  <i data-action="click->onboarding#removeSkill" class="icon-x" data-index="${index}"></i>
                </span>
              </li>`
            );
          }
        });
      } else {
        let is_shown = false;
        const skill_list = document.getElementById("selected-options");
        if (skill_list) {
          is_shown = skill_list.querySelectorAll("li").length == 0;
        }

        if (is_shown) {
          displayDiv.append(
            `<div id="skill_fallback_case" class='flex w-full flex-col items-center p-[30px_20px] bg-[#05123705] rounded-[8px]'><strong class='text-[16px] font-medium leading-6 font-text_font text-[#051237E0] block text-center'>No skills added yet</strong></div>`
          );
        }
      }
    });
  }

  handleFile(event) {
    const input = event.target;
    if (input.files && input.files[0]) {
      const url = URL.createObjectURL(input.files[0]);
      this.imagePreviewTarget.src = url;
    }
  }

  removeLanguage(event) {
    event.currentTarget?.parentNode?.parentNode?.parentNode?.remove();
  }

  addMoreExperience(event) {
    event.preventDefault();

    const splide__list = document.getElementById("splide__list");

    const exp_title = document.querySelectorAll(".emp_title");
    const exp_title_field = exp_title[splide_slider.length - 1];

    const emp_start_year = document.querySelectorAll(".emp_start_year");
    const emp_start_year_field = emp_start_year[splide_slider.length - 1];

    const emp_end_year = document.querySelectorAll(".emp_end_year");
    const emp_end_year_field = emp_end_year[splide_slider.length - 1];
    let is_valid = true;

    if (!exp_title_field || exp_title_field.value.trim() === "") {
      exp_title_field?.classList.add("!border-rose-500");
      splide__list.classList.remove("just_check");
      is_valid = false;
    } else {
      exp_title_field?.classList.remove("!border-rose-500");
    }

    if (!emp_start_year_field || emp_start_year_field.value.trim() === "") {
      emp_start_year_field?.classList.add("!border-rose-500");
      splide__list.classList.remove("just_check");
      is_valid = false;
    } else {
      emp_start_year_field?.classList.remove("!border-rose-500");
    }

    if (emp_end_year_field.getAttribute("required") != null) {
      if (!emp_end_year_field || emp_end_year_field.value.trim() === "") {
        emp_end_year_field?.classList.add("!border-rose-500");
        splide__list.classList.remove("just_check");
        is_valid = false;
      } else {
        emp_end_year_field?.classList.remove("!border-rose-500");
      }
    }

    if (is_valid) {
      splide__list.classList.add("just_check");
      if (splide_slider.length == 1) {
        $("#add_more_record").click();
      }
      splide_slider.add();
      splide_slider.go(splide_slider.length - 1);
      const arrows = document.getElementsByClassName("splide__arrows")[0];
      if (arrows) {
        arrows?.classList.remove("!hidden");
      }
    }
  }

  addMoreEducation(event) {
    event.preventDefault();

    const splide__list = document.getElementById("splide__list");

    const edu_degree = document.querySelectorAll(".edu_degree");
    const edu_degree_field = edu_degree[splide_slider.length - 1];

    const edu_field = document.querySelectorAll(".edu_field");
    const edu_field_field = edu_field[splide_slider.length - 1];

    const edu_start_year = document.querySelectorAll(".edu_start_year");
    const edu_start_year_field = edu_start_year[splide_slider.length - 1];

    const edu_end_year = document.querySelectorAll(".edu_end_year");
    const edu_end_year_field = edu_end_year[splide_slider.length - 1];
    let is_valid = true;

    if (!edu_degree_field || edu_degree_field.value.trim() === "") {
      edu_degree_field?.classList.add("!border-rose-500");
      splide__list.classList.remove("just_check");
      is_valid = false;
    } else {
      edu_degree_field?.classList.remove("!border-rose-500");
    }

    if (!edu_field_field || edu_field_field.value.trim() === "") {
      edu_field_field?.classList.add("!border-rose-500");
      splide__list.classList.remove("just_check");
      is_valid = false;
    } else {
      edu_field_field?.classList.remove("!border-rose-500");
    }

    if (!edu_start_year_field || edu_start_year_field.value.trim() === "") {
      edu_start_year_field?.classList.add("!border-rose-500");
      splide__list.classList.remove("just_check");
      is_valid = false;
    } else {
      edu_start_year_field?.classList.remove("!border-rose-500");
    }

    if (edu_end_year_field.getAttribute("required") != null) {
      if (!edu_end_year_field || edu_end_year_field.value.trim() === "") {
        edu_end_year_field?.classList.add("!border-rose-500");
        splide__list.classList.remove("just_check");
        is_valid = false;
      } else {
        edu_end_year_field?.classList.remove("!border-rose-500");
      }
    }

    if (is_valid == true) {
      splide__list.classList.add("just_check");
      if (splide_slider.length == 1) {
        $("#add_more_record").click();
      }
      splide_slider.add();
      splide_slider.go(splide_slider.length - 1);
      const arrows = document.getElementsByClassName("splide__arrows")[0];
      if (arrows) {
        arrows?.classList.remove("!hidden");
      }
    }
  }

  removeNonSplideSlide() {
    var ulElement = document.querySelector("#splide__list");
    var liElements = ulElement.querySelectorAll("li");
    liElements.forEach(function (li) {
      if (!li.id) {
        li.remove();
      }
    });
  }

  submitForm(event) {
    event.preventDefault();
    this.removeNonSplideSlide();
    event.currentTarget.form.requestSubmit();
  }

  toggleDescription(event) {
    const description = event.currentTarget.dataset.description;
    const length = event.currentTarget.dataset.length;
    const textContainer = event.currentTarget.previousElementSibling;

    if (event.currentTarget.textContent.toLowerCase() === "show more") {
      event.currentTarget.textContent = "Show Less";
      textContainer.innerHTML = this.formatText(description);
    } else {
      event.currentTarget.textContent = "Show More";
      textContainer.innerHTML = this.formatText(
        this.handleLongText(description.trim(), length != null ? 202 : 302)
      );
    }
  }

  handleLongText(text, maxLength) {
    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + `...`;
    }
    return text;
  }

  formatText(text) {
    return text.replace(/\n/g, "<br>");
  }

  currentlyPresent(event) {
    const end_date =
      event?.currentTarget?.parentNode?.previousElementSibling?.lastElementChild
        ?.lastElementChild;

    if (!end_date) {
      return;
    }

    end_date?.classList?.remove("!border-rose-500");

    if (event.currentTarget.checked) {
      end_date.removeAttribute("required");
      end_date.disabled = true;
      end_date.value = "";
    } else {
      end_date.setAttribute("required", true);
      end_date.disabled = false;
    }
  }

  removeSkill(event) {
    const skillElement = event.target.parentElement;
    const skillId = parseInt(event.target?.dataset?.skillId, 10);

    if (skillId) {
      let existingSkillsInput = this.existingSkillsInputTarget;

      if (existingSkillsInput) {
        let existingSkillIds = JSON.parse(existingSkillsInput.value || "[]");

        if (!existingSkillIds.includes(skillId)) {
          existingSkillIds.push(skillId);
        }

        existingSkillsInput.value = JSON.stringify(existingSkillIds);
      }
    }
    const skillName = skillElement.innerText;
    const skillSelector = document.getElementById("skills_multiples");
    for (let i = skillSelector.options.length - 1; i >= 0; i--) {
      if (skillSelector.options[i].innerText === skillName) {
        skillSelector.remove(i);
      }
    }
    skillElement.parentElement.remove();
  }
}
