// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  reconnect(event) {
    if (consumer.connection.isActive()) {
      consumer.connection.reopen();
    }
  }

  toggleForms() {
    const magicLinkForm = document.getElementById("magic-link-form");
    const passwordForm = document.getElementById("password-form");

    magicLinkForm.classList.toggle("hidden");
    passwordForm.classList.toggle("hidden");
  }
}
