import { Controller } from "@hotwired/stimulus";
var splideElement = null;

export default class extends Controller {
  static targets = [
    "list",
    "toggleButton",
    "form",
    "tab",
    "asideCategoryDrawerModal",
    "asideCategoryDrawerChildren",
  ];
  connect() {
    splideElement = document.getElementById("success-stories-sliders"); // Check for the ID
    $(this.element).find("#select6").select2();
    $(this.element).find("#select7").select2();
    $(this.element).find("#select_company_sort").select2();
    $(this.element).find("#countries-select").select2();
    if (splideElement) {
      // If the element exists
      new Splide(".splide", {
        perPage: 3,
        autoplay: true,
        padding: 10,
        interval: 8000,
        drag: "free",
        updateOnMove: true,
        gap: "20px",
        pagination: false,
        classes: {
          prev: "splide__arrow--prev icon-chevron-left",
          next: "splide__arrow--next icon-chevron-right",
        },
        breakpoints: {
          1366: {
            focus: "center",
          },
          1280: {
            perPage: 1.5,
          },
          767: {
            perPage: 1.1,
          },
        },
      }).mount();
    }

    $(".accordion-button").click(function () {
      // Close any open accordion items
      $(".accordion-content").not($(this).next()).slideUp();
      $(".accordion-icon")
        .not($(this).find(".accordion-icon"))
        .removeClass("rotate-90");
      $(".accordion-item").not($(this).parent()).removeClass("bg-[#FAFAFB]");

      // Toggle the current accordion item
      $(this).next(".accordion-content").slideToggle(300);
      $(this).find(".accordion-icon").toggleClass("rotate-90");
      $(this).parent(".accordion-item").toggleClass("bg-[#FAFAFB]");
    });
  }

  handleSearch(event) {
    event.currentTarget.form.requestSubmit();
  }

  disconnect() {
    $(this.element).find("#select6").select2("destroy");
    $(this.element).find("#select7").select2("destroy");
    $(this.element).find("#countries-select").select2("destroy");
    $(this.element).find("#select_company_sort").select2("destroy");
    $(this.element).find("#skill_search").select2("destroy");

    splide_slider = null;
  }

  searchProfessionals(event) {
    event.preventDefault();
    console.log("searchProfessionals");
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 300);
  }

  openDrawer() {
    console.log("openDrawer");
    this.asideCategoryDrawerModalTarget.classList.remove("hidden");
    this.asideCategoryDrawerModalTarget.classList.add("flex");
    this.asideCategoryDrawerChildrenTarget.classList.add("!translate-x-[0]");
    document.body.style.overflow = "hidden";
  }

  closeDrawer() {
    this.asideCategoryDrawerModalTarget.classList.remove("flex");
    this.asideCategoryDrawerModalTarget.classList.add("hidden");
    this.asideCategoryDrawerChildrenTarget.classList.remove("!translate-x-[0]");
    document.body.style.overflow = "auto";
  }

  submitDrawerForm(event) {
    this.closeDrawer();
    event.currentTarget.form.requestSubmit();
  }

  toggleAddMore() {
    console.log("toggleAddMore");
    const button = this.toggleButtonTarget;
    const list = this.listTarget;
    const listItems = list.querySelectorAll("li");

    if (button.textContent.trim() === "Show Less") {
      // Hide all list items except the first 2
      listItems.forEach((item, index) => {
        if (index >= 2) {
          item.classList.add("fade-out");
          item.addEventListener(
            "transitionend",
            function handler() {
              item.style.display = "none";
              item.removeEventListener("transitionend", handler);
            },
            { once: true }
          );
        }
      });

      // Change button text to "Show More"
      button.textContent = "Show More";
    } else {
      // Show all hidden list items
      listItems.forEach((item, index) => {
        if (index >= 2) {
          item.style.display = "";
          // Trigger reflow
          item.offsetHeight;
          item.classList.remove("fade-out");
        }
      });

      // Change button text to "Show Less"
      button.textContent = "Show Less";
    }
  }

  activateTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
    this.showLoader();
  }

  showLoader() {
    if (this.hasTeamDataTarget) {
      this.teamDataTarget.classList.add("hidden");
    }

    if (this.hasInsightDataTarget) {
      this.insightDataTarget.classList.add("hidden");
    }
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove("hidden");
    }
  }
}
